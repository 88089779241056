// Here you can add other styles
.labels-style {
  margin-right: 1rem;
  align-self: center;
  width: 7rem;
  margin-bottom: 8px;
}
.form_modal {
  display: flex;
  .btn-close {
    --cui-btn-close-bg: url("../assets/images/fca/close.png");
    --cui-btn-close-opacity: 1;
  }
.card {
    border: none;
  }
  .modal-dialog {
    max-width: 75rem;
  }
  .modal-content {
    border-radius: 1.2rem;
    color: #091C14;
    font-weight: 500;
.uploads {
      margin: 3rem 0 10rem 0;
    }
    .new-table {
      tr {
        border: transparent;
        line-height: 50px;
      }
      td {
        ul {
          max-width: 25rem;
          list-style-type: none;
          padding: 0 0 0 1rem;
          div {
            display: flex;
            justify-content: space-between;
            li {
              display: table-cell;
              width: 10rem;
            }
          }
        }
      }
    }
    .next {
      width: 116px;
      height: 46px;
      border-radius: 16px;
      background: $layout-color;
      border: none;
    }
    .split,.non-split {
      background: #cfedfa;
      width: 86px;
      height: 38px;
      border-radius: 1rem;
      color: #2F99C7;
      margin-right: .5rem;
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .success {
      color: #3DBC59;
    }
    .error {
      color: #C33F11;
    }
    .tb-bg {
      --cui-table-bg: #e9f7fd;
      table {
        --cui-table-bg: #e9f7fd;
      }
    }
    .switch {
      .form-check-input {
        height: 1.1rem;
        width: 2.5rem;
        margin-right: 0.5rem;
      }
      .form-check-input:checked {
        background-color: #2F99C7;
        border-color: #2F99C7;
      }
    }
    .non-split {
      background: white;
    }
    .arrow {
      position: absolute;
    }
    .arrow-style {
      padding-left: 9rem;
      position: relative;
      img,p {
        position: absolute;
        right: 2rem;
        top: 6px;
      }
      p {
        right: .5rem;
        color: #909090;
        font-size: 1rem;
      }
    }
    .modal-title {
      font-size: 22px;
      font-weight: 600;
    }
    .fw-bold {
      font-size: 18px;
    }
    .form-control,
    .react-select-form-control {
      min-height: 3rem;
      border-radius: 12px;
      margin-bottom: 8px;
    }
    .is-valid .react-select-form-control,
    .is-invalid .react-select-form-control {
      background-position: bottom 15px right 22px;
    }
  }
  .react-tabs__tab--selected {
    background: none;
    border-color: transparent;
    border-bottom: 2px solid $layout-color;
    font-weight: 700;
    color: $layout-color;
  }
  .react-tabs__tab {
    font-size: 20px;
    background: transparent;
    border: 0;
    margin: 0 1rem 0 0;
    font-weight: 500;
    padding: 0px 12px;
  }
  .react-tabs__tab--selected::after {
    background: $layout-color;
    content: "";
    display: block;
    height: 4px;
    margin-top: 8px;
    width: 25px;
  }
  .react-tabs__tab-list {
    display: flex;
  }
}
.nav-group-items {
  margin-left: .7rem;
}

.modal-scrollable {
  max-height: 400px;
  overflow-y: scroll;
}
.sidebar.right:not(.sidebar-end) {
  right: 0 !important;
  left: auto !important;
}

input[type="file"] {
  display: none;
}

.file input[type="file"] {
  display: block;
  min-height: 2.4rem !important;
  height: 2rem;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.right.hide:not(.sidebar-end) {
  margin-right: calc(-1 * var(--cui-sidebar-width) - 20rem) !important;
}
.sidebar.right {
  width: 35rem;
  height: max-content;
}
.is-invalid .react-select-form-control {
  border-color: var(--cui-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e55353%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e55353%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: bottom 6px right 30px;
}

.is-valid .react-select-form-control {
  border-color: var(--cui-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%232eb85c%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  background-position: bottom 6px right 30px;
}

body div#root > .login {
  background-image: none !important;
}

body div#root > div {
  background-image: url('../assets/images/backgrounds/youssef-abdelwahab-qyzo7TDSVQs-unsplash.jpg') !important;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.login {
  width: 100%;
  height: 46px;
  border-radius: 16px;
  background: $layout-color;
  border: none;
  font-weight: 500;
}
.login:hover {
  background: $layout-color;
}

.bg-light {
  background-color: rgba(235, 237, 239, 0.90) !important;
}

.funds-control-account-select__option, .funds-control-account-select__single-value{
  white-space: pre-wrap !important;
}

.date-range {
  position: fixed;
  right: 0;
  top: 35rem;
  z-index: 3;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.react-datepicker-wrapper {
  display: inline !important;
}

.invalid-feedback {
  display: block;
}

.border-around {
  border: 1px solid #ccc;
  padding: 1rem;
}
.sub-section {
  background-color: $layout-color;
  padding: 0.5rem;
  text-align: center;
  margin: 2rem 0;
  color: #fff;
}
.form-select {
  height: 30px;
  font-size: .9rem;
}

// Listings Page
.listing {
  border-radius: 1rem;
  .card-body {
    padding: 0;
    .rdt_TableHeadRow,
    thead tr th{
      color: #909090;
      font-size: 1rem;
    }
    .rdt_TableRow,
    tbody tr td {
      font-size: 1rem;
      color: $layout-color;
      font-weight: 500;
      .btn {
        border: 0;
        margin-right: 10px;
        border-radius: 8px !important;
      }
      .send {
        background: #004D3B;
      }
      .edit {
        background: #D2E0DD;
      }
      .delete {
        background: rgba(195, 63, 17, 0.1);
      }
      .mail {
        background: #49c3e7;
      }
    }
  }
}

// React Select
.select-box {
  position: relative;
}
.hover-info {
  position: absolute;
  top: -2rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  z-index: 9999;
  min-width: 15rem;
}

.swal2-popup code {
  background-color: red;
  color: white;
  border-radius: 5px;
  font-size: 0.775em;
  padding: 5px;
  font-family: inherit;
}

.search button.clear-btn {
  background-color: transparent;
  background-image: url("../assets/images/fca/close.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  height: 12px;
  right: 60px;
  position: absolute;
  top: 12px;
  width: 12px;
  display: none;
}

.search input.in-use ~ button.clear-btn {
  display: block;
}

.input-right-hint {
  position: absolute;
  top: 3px;
  right: 45px;
  color: #808080;
  font-size: 12px;
}

*:has(> .input-right-hint) {
  position: relative;
}

.progress-line {
  line-height: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-width: 5px;
  max-width: 100%;
  color: #025346;
  border-color: #025346;
}

.custom-bg-primary{
  background-color: $layout-color;
}
.fca-form {
  .form-control-sm {
    min-height: 3rem;
    border-radius: 12px;
    margin-bottom: 8px;
  }
  label {
    margin-bottom: 0.5rem;
  }
  .form-check-input {
    height: 1.1rem;
    width: 2.5rem;
    margin-right: 0.5rem;
  }
  .form-check-input:checked {
    background-color: #2F99C7;
    border-color: #2F99C7;
  }
  .form-check-inline .form-check-input,
  .check .form-check-input {
    height: 1rem;
    width: 1rem;
  }
  .sub-section {
    .form-control-sm {
      min-height: 1rem;
      margin-bottom: 2px;
    }
  }
  .next {
    width: 116px;
    height: 46px;
    border-radius: 16px;
    background: $layout-color;
    border: none;
    font-weight: 500;
  }
}
.dropdown-menu.show {
  transform: translate(64px, 55px) !important;
}
.checked .form-check-input  {
  height: .8rem !important;
  width: .8rem !important;
}
.checked .form-check {
  padding-top: .2rem;
}

// Calendar Icon
.react-datepicker__input-container .react-datepicker__calendar-icon {
  top: .5rem;
  right: 2.3rem;
  padding: 0;
}

.file-budget input[type="file"] {
  display: block;
}

.bordered {
  padding: 2rem !important;
  .modal-content {
    border-radius: 0;
  }
  .new-table {
    thead, tbody, tfoot, tr, td, th {
      border-width: 1px;
      padding: 0 10px;
    }
    tr {
      border: 1px solid !important;
    }
  }
}

.files input[type="file"]{
  display: block;
  min-height: 2.4rem !important;
}

.files input[type="file"] {
  min-height: auto !important;
}

.heading-main {
  font-size: 22px;
  color: #091C14;
  font-weight: 600;
  margin: 0;
}

// SideBar
.sidebar {
  background: $layout-color;
  .sidebar-nav .nav-link {
    color: $white;
    width: 90%;
    margin-left: 5%;
    border-radius: 16px;
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: 400;
  }
  .sidebar-nav .nav-link:hover,
  .sidebar-nav .nav-link.active {
    background: $white;
    color: $layout-color;
  }
  .sidebar-nav .nav-link:hover .nav-icon,
  .sidebar-nav .nav-link.active .nav-icon {
    color: $layout-color;
  }
  .sidebar-nav .nav-group-toggle::after {
    height: 26px;
    background-size: cover;
  }
}

// Dashboard
.dashboard-card {
  border: 1px solid #06937D;
  border-radius: 1rem;
  padding: 0 1.5rem 0 1.5rem;
  color: #000;
  p {
    font-weight: 500;
  }
  h6 {
    font-size: 20px;
    font-weight: 700;
  }
}
.dashboard-card.green {
  color: #fff;
  background: $layout-color;
}
.chart-heading {
  h5{
    font-size: 24px;
    color: #000;
    font-weight: 600;
  }
  h6 {
    color: #909090;
    font-size: 20px;
    font-weight: 700;
  }
}
.rdrDateRangePickerWrapper,
.rdrDefinedRangesWrapper,
.rdrCalendarWrapper {
  border-radius: 1rem;
}
.rdrStaticRanges > :first-child{
  border-top-left-radius: 1rem;
}
.rdrDateDisplayWrapper {
  border-top-right-radius: 1rem;
}

.rounded-border-22 {
  border-radius: 22px;
}
